import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {environment} from '../environments/environment';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {AppConfig} from '@shared/models/app-config.interface';
import {addGeometryToSvg, ILayerConfig} from 'viz-common';
import {ApiUrls} from '@shared/models/api-urls.interface';
import {LayerGroupConfig} from '@shared/models/layer-group.interface';
import {Store} from '@ngxs/store';
import {AppConfigActions} from './store/actions/app-config.actions';
import {IAuthConfig} from '@app/auth-config.interface';
import SetConfig = AppConfigActions.SetConfig;

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private host = environment.host ? environment.host : window.location.protocol + '//' + window.location.host;
  config: BehaviorSubject<AppConfig> | undefined;

  constructor(@Inject(LOCALE_ID) public locale: string, private http: HttpClient, private store: Store) {}

  loadAuthConfig(): Observable<IAuthConfig> {
    const path = this.host.includes('test.')
      ? 'assets/configs/auth-config-test.json'
      : 'assets/configs/auth-config.json';
    return this.http.get<IAuthConfig>(path);
  }

  loadAppConfig(): Observable<AppConfig> {
    const configName =
      this.locale === 'de' ? 'assets/configs/app-config-prod-de.json' : 'assets/configs/app-config-prod-en.json';
    return this.http.get<AppConfig>(configName).pipe(
      map((data: AppConfig) => {
        this.addHostToRelativeUrls(data.apiUrls);
        this.extendLayersProperties(data.layerGroups);

        this.config = new BehaviorSubject<AppConfig>(data);
        this.store.dispatch(new SetConfig(data as AppConfig));

        return data as AppConfig;
      }),
    );
  }

  private addHostToRelativeUrls(apiUrls: ApiUrls): void {
    for (const key in apiUrls) {
      if (apiUrls[key][0] === '/') {
        apiUrls[key] = this.host + apiUrls[key];
      }
    }
  }

  private extendLayersProperties(layerGroups: LayerGroupConfig[]): void {
    layerGroups.forEach((group) => {
      for (const layerKey in group.layers) {
        const layer = group.layers[layerKey];
        this.setApiUrl(layer);
        this.prepareLayerIcon(layer);
      }
    });
  }

  private prepareLayerIcon(layer: ILayerConfig): void {
    if (layer.icon.length > 20) {
      layer.icon = addGeometryToSvg(layer.icon);
    }
  }

  private setApiUrl(layer: ILayerConfig) {
    if (layer.apiUrl[0] === '/') {
      layer.apiUrl = this.host + layer.apiUrl;
    }
  }
}
